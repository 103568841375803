import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon'

export const Rail = (props: SvgIconProps) => (
    <SvgIcon {...props} fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            clipRule="evenodd"
            d="M12 4.66669H1V8.33333V8.33335L1.00006 13.2222H1V16.8889H1.6279C1.77933 18.2661 2.94284 19.3333 4.36122 19.3333C5.77959 19.3333 6.9431 18.2661 7.09453 16.8889H7.73881C7.89022 18.2661 9.05374 19.3334 10.4721 19.3334C11.8905 19.3334 13.054 18.2661 13.2054 16.8889H13.8501C14.0015 18.2661 15.165 19.3333 16.5834 19.3333C18.0018 19.3333 19.1653 18.2661 19.3167 16.8889H23L21.7778 13.2222H19.9444V8.33333H13.2222V8.33336H7.72222L12 8.33335V4.66669ZM5.88883 5.88885H2.22217V8.3333H5.88883V5.88885ZM7.1109 5.88884H10.7776V8.33329H7.1109V5.88884ZM4.36122 17.9583C3.70543 17.9583 3.15861 17.5021 3.02011 16.8889H5.70232C5.56382 17.5021 5.017 17.9583 4.36122 17.9583ZM16.5834 17.9583C15.9276 17.9583 15.3808 17.5021 15.2423 16.8889H17.9245C17.786 17.5021 17.2392 17.9583 16.5834 17.9583ZM10.4721 17.9584C9.81633 17.9584 9.2695 17.5021 9.13101 16.8889H11.8132C11.6747 17.5021 11.1279 17.9584 10.4721 17.9584Z"
            fillRule="evenodd"
        />
    </SvgIcon>
)

export default Rail
