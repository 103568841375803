import {FormattedMessage} from 'react-intl'

import {styled} from '@waybridge/wui'
import Box from '@waybridge/wui/Box'
import Stack from '@waybridge/wui/Stack'
import Typography from '@waybridge/wui/Typography'

import FooterLinks from '@/components/FooterLinks'
import MinehubLogo from '@/components/MinehubLogin/MinehubLogo'

import {ModalSize} from './modal-size.enum'

const ModalOverlay = styled('div')(({theme}) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    padding: '40px 20px',
    alignItems: 'center',
    boxSizing: 'border-box',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary[50],
}))

const ModalWrapper = styled('div', {shouldForwardProp: (prop) => prop !== 'modalSize'})<{modalSize: ModalSize}>(
    ({modalSize}) => ({
        flex: 2,
        width: '100%',
        display: 'flex',
        marginBlock: '20px',
        flexDirection: 'column',
        justifyContent: 'center',
        maxWidth: modalSize === ModalSize.SMALL ? '500px' : '640px',
    }),
)

const Modal = styled(Stack)(({theme}) => ({
    gap: '24px',
    borderRadius: '4px',
    flexDirection: 'column',
    boxShadow: (theme.shadows as string[])[12],
    backgroundColor: theme.palette.secondary.contrastText,
}))

export const ModalTitle = styled(Typography)(({theme}) => ({
    padding: '20px',
    fontWeight: 300,
    borderBottom: `1px solid ${theme.palette.primary.light}`,
}))

export const ModalForm = styled('form')`
    gap: 24px;
    display: flex;
    flex-direction: column;
`

export const ModalActions = styled(Stack)(({theme}) => ({
    gap: '24px',
    padding: '20px',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.primary[50],
}))

type ModalPageProps = {
    header?: React.ReactNode
    modalSize?: ModalSize
    children: React.ReactNode
}

const ModalPage = ({header, modalSize = ModalSize.SMALL, children}: ModalPageProps) => (
    <ModalOverlay>
        {header}
        <ModalWrapper modalSize={modalSize}>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <MinehubLogo />
                <Typography display="block" fontWeight="bold" mb="50px" variant="h4">
                    <FormattedMessage defaultMessage="Trade Management" />
                </Typography>
            </Box>
            <Modal>{children}</Modal>
        </ModalWrapper>
        <Box sx={{display: 'flex', flex: 1, alignItems: 'flex-end'}}>
            <FooterLinks />
        </Box>
    </ModalOverlay>
)
export default ModalPage
