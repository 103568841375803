import posthog from 'posthog-js'
import {useLazyLoadQuery} from 'react-relay'
import {Navigate, useParams} from 'react-router'

import Fallback from '@waybridge/wui/Fallback'

import useFlags from '@/hooks/useFlags'
import * as paths from '@/pages/routes/paths'
import ShipmentNotFound from '@/pages/ShipmentDetails/ShipmentNotFound'
import {usePermissions} from '@/providers/PermissionAndNavProvider'

import {ShipmentLinkQuery} from '@/__generated__/ShipmentLinkQuery.graphql'

import {getShipmentLinkDelivery} from './ShipmentLink.queries'

const ShipmentGetLocation = ({shipmentPk}: {shipmentPk: number}) => {
    const data = useLazyLoadQuery<ShipmentLinkQuery>(getShipmentLinkDelivery, {shipmentPk})
    const {orgPk} = usePermissions()

    const buyerPk = data.delivery?.billOfLading?.buyerOrg?.pk
    const locationPk = data.delivery?.locationConveyance?.location?.pk

    if (!locationPk) {
        return <ShipmentNotFound />
    }

    const path = paths.getShipmentDetailsPath(shipmentPk, true, buyerPk === orgPk, locationPk)

    if (!path) {
        return null
    }

    posthog.capture('ShipmentRedirect', {to: 'shipment-edit'})
    return <Navigate replace to={path} />
}

interface Params {
    shipmentPk: string
    [key: string]: string | undefined
}

const ShipmentLink = () => {
    const {launchDarklyFlagsLoaded} = usePermissions()
    const {shipmentPk} = useParams<Params>()
    const {newShipmentEdit, southwireNewShipmentDetails} = useFlags(true)

    if (!launchDarklyFlagsLoaded) {
        return <Fallback height="100%" />
    }

    // eslint-disable-next-line curly -- FIXME
    if (!shipmentPk) return <ShipmentNotFound />

    const shipmentId = parseInt(shipmentPk, 10)

    if (!newShipmentEdit) {
        // redirect to legacy shipments page
        return <ShipmentGetLocation shipmentPk={shipmentId} />
    }

    let path = paths.getShipmentDetailsPath(shipmentId, false)
    if (!path) {
        return <ShipmentNotFound />
    }

    if (southwireNewShipmentDetails) {
        path = `${path}?isEditing=true`
    }

    posthog.capture('ShipmentRedirect', {to: 'shipments-better-edit'})
    return <Navigate replace to={path} />
}

export {ShipmentLink}
