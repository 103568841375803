import {useEffect} from 'react'
import {Outlet, useSearchParams} from 'react-router-dom'

import Box from '@waybridge/wui/Box'
import * as Layout from '@waybridge/wui/Layout'
import Suspense from '@waybridge/wui/Suspense'

import {ResponsiveNav} from '@/components/Nav'
import {useAuth} from '@/providers/AuthProvider'
import RelayProvider from '@/providers/RelayProvider'

export const PageLayout = ({children}: {children: React.ReactNode}) => (
    <Layout.Container>
        <Layout.TopBar>
            <ResponsiveNav />
        </Layout.TopBar>
        <Layout.Content style={{alignItems: 'center', justifyContent: 'center'}}>{children}</Layout.Content>
    </Layout.Container>
)

export const PasswordManagerWrapper = () => {
    const auth = useAuth()
    const [searchParams] = useSearchParams()

    const token = searchParams.get('token')
    const uid = searchParams.get('uid')

    useEffect(() => {
        if (token && uid) {
            auth.logout(false)
            auth.setPwResetToken(`token=${token}&uid=${uid}`)
        }
    }, [token, uid, auth])

    return (
        <Box sx={{height: '100%'}}>
            <RelayProvider>
                <Suspense>
                    <Outlet />
                </Suspense>
            </RelayProvider>
        </Box>
    )
}

export default PasswordManagerWrapper
