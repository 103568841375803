import {ReactNode, useState} from 'react'
import type {SingletonObject} from '@tippyjs/react'
import Tippy, {TippyProps} from '@tippyjs/react'
import {isNil} from 'lodash'
import {Placement} from 'tippy.js'

import 'tippy.js/dist/tippy.css'
import './Tooltip.less'

export type TooltipProps = Omit<TippyProps, 'children'> & {
    /** the content to be displayed in the tooltip */
    content?: ReactNode
    /** whether the tooltip should be shown immediately */
    visible?: boolean
    /** whether to show an arrow */
    arrow?: boolean
    /** interactive? */
    interactive?: boolean
    /** placement of the tooltip relative to the children */
    placement?: Placement
    /** The content that the tooltip will be displayed and mousing over will trigger the tooltip to be displayed  */
    children?: ReactNode
    /** singleton target object */
    singleton?: SingletonObject
}

/**
 * This is the standard 'info' tooltip -- dark background, white text.
 *
 * @deprecated Please use a WUI component
 */
export const TooltipInner = ({arrow = false, theme = 'info', content, singleton, ...props}: TooltipProps) => {
    const [mounted, setMounted] = useState(false)

    const lazyPlugin = {
        fn: () => ({
            onShow: () => setMounted(true),
            onHidden: () => setMounted(false),
        }),
    }

    const computedProps = {...props} as TippyProps
    computedProps.theme = theme
    computedProps.arrow = arrow

    if (isNil(singleton)) {
        computedProps.plugins = [lazyPlugin, ...(props.plugins || [])]
        computedProps.content = mounted ? content : ''
    } else {
        computedProps.content = content
    }

    return <Tippy {...computedProps} />
}

type OuterTooltipProps = TooltipProps & {
    /** `className` for the `div` that wraps the children of the tooltip */
    innerDivClassName?: string
    /** `aria-label` for the `div` that wraps the children of the tooltip */
    innerDivAriaLabel?: string
}

/**
 * Tooltip component. By default this is a normal, information tooltip.
 *
 * @deprecated Please use a WUI component
 */
export const Tooltip = ({innerDivClassName, innerDivAriaLabel, children, ...props}: OuterTooltipProps) => (
    <TooltipInner {...props}>
        <div aria-label={innerDivAriaLabel} className={innerDivClassName}>
            {children}
        </div>
    </TooltipInner>
)

type GuideTooltipProps = TooltipProps & {
    header?: string
}

/**
 * @deprecated Please use a WUI component
 */
export const GuideTooltip = ({header, content, children, ...props}: GuideTooltipProps) => (
    <TooltipInner
        arrow={false}
        interactive={true}
        theme="guide"
        {...props}
        content={
            <>
                {/* eslint-disable-next-line react/jsx-no-leaked-render -- FIXME */}
                {header && <div className="guide-header">{header}</div>}
                {content}
            </>
        }>
        {children}
    </TooltipInner>
)

export default Tooltip
