import {SvgIcon} from '@mui/material'
import cn from 'classnames'

import {ConveyanceSVGProps} from './props'

export const Truck = ({background, fill, fontSize = 'medium', className}: ConveyanceSVGProps) => (
    <SvgIcon
        className={cn('icon icon-truck', className, {
            'with-background': background,
        })}
        fill={fill}
        fontSize={fontSize}
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 11.6a1.6 1.6 0 110 3.2 1.6 1.6 0 010-3.2zm-7.2 0a1.6 1.6 0 110 3.2 1.6 1.6 0 010-3.2zm0-7.2a.8.8 0 01.8.8v5.748a2.393 2.393 0 00-.8-.148 2.4 2.4 0 00-2.4 2.4H.8a.8.8 0 01-.8-.8V7.6c0-1.764 1.435-3.2 3.2-3.2zM16 2v10.4a.8.8 0 01-.8.8h-.8a2.394 2.394 0 00-4.653-.8H7.053a2.385 2.385 0 00-.653-.976V2H16zM4 6h-.8a1.6 1.6 0 00-1.6 1.6v.8H4V6z"
            fillRule="evenodd"
        />
    </SvgIcon>
)
