import {useEffect} from 'react'
import {startCase} from 'lodash'
import posthog from 'posthog-js'
import {useLocation} from 'react-router-dom'

import {configValue} from '@/utils/config'

import * as paths from './paths'

/**
 * Quick + basic way to customise the index path based on user roles, to avoid issues
 * where the user gets directed to a page they don't have access to. Should be restructured when
 * we implement contextual nav.
 */
export const getTopPagePath = (roleCodes: Set<string>, isPrincipal: boolean) => {
    const defaultPath = paths.getTradeManagerPath()
    const adminPath = paths.getOrgSettingsPath()

    if (isPrincipal) {
        if (roleCodes.has('APPROVER') || roleCodes.has('BUYER')) {
            return defaultPath
        }

        if (roleCodes.has('PLANT_MANAGER') || roleCodes.has('PLANT_WORKER')) {
            return paths.shipmentsPath
        }
    }

    if (roleCodes.has('WAYBRIDGE_ADMIN')) {
        return adminPath
    }

    // trades is always a good page to navigate to
    return defaultPath
}

/**
 * use a simplified path to set the title so viewing on analytics apps is easier
 */
const simplifiedPageTitle = (pathname: string, platformName: string) =>
    // eslint-disable-next-line require-unicode-regexp, prefer-named-capture-group -- FIXME
    startCase(pathname.replace(/[0-9]/g, '').replace(/(\/+)/g, '/').replace(/\//g, ' ').trim()) || platformName

const posthogApiKey = configValue('PH_API_KEY')

export const usePosthogPageView = () => {
    const location = useLocation()

    useEffect(() => {
        if (posthogApiKey) {
            posthog.capture('$pageview', {
                $set_once: {
                    pagePath: location.pathname + location.search,
                    pageLocation: window.location.href,
                    pageTitle: simplifiedPageTitle(location.pathname, 'MineHub'),
                },
            })
        }
    }, [location])
}
