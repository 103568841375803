import {FormattedMessage} from 'react-intl'

import ButtonLink from '@waybridge/wui/ButtonLink'
import {FullPageMessage} from '@waybridge/wui/Layout'

import {shipmentsPath} from '@/pages/routes/paths'

export const ShipmentNotFound = () => (
    <FullPageMessage
        actions={
            <ButtonLink to={`/${shipmentsPath}`}>
                <FormattedMessage defaultMessage="Go to shipments" />
            </ButtonLink>
        }
        title={<FormattedMessage defaultMessage="Shipment not found" />}>
        <FormattedMessage defaultMessage="The shipment you've been looking for can't be found. Try checking the shipment ID or go to shipments." />
    </FullPageMessage>
)

export default ShipmentNotFound
