import React, {useMemo, useRef, useState} from 'react'
import {useAtom} from 'jotai'
import {isNil, map} from 'lodash'
import {FormattedMessage, useIntl} from 'react-intl'
import {useLocation} from 'react-router'
import {SwitchTransition} from 'react-transition-group'

import {styled} from '@waybridge/wui'
import Box from '@waybridge/wui/Box'
import Fade from '@waybridge/wui/Fade/Fade'
import IconButton from '@waybridge/wui/IconButton'
import {FlagOutlinedIcon, QuestionOutlinedIcon} from '@waybridge/wui/Icons'
import {MenuItem} from '@waybridge/wui/MenuItem'
import RouterLink from '@waybridge/wui/RouterLink'
import Select from '@waybridge/wui/Select'
import Skeleton from '@waybridge/wui/Skeleton'
import Suspense from '@waybridge/wui/Suspense'
import Logo from '@waybridge/wui/ThemeProvider/minehub-theme/components/Logo'
import Tooltip from '@waybridge/wui/Tooltip'

import {languages} from '@/app/locale'
import {localeAtom, store} from '@/app/store'
import Menu from '@/components/Nav/Menu'
import NavItem from '@/components/Nav/NavItem'
import SubNavContainer from '@/components/Nav/SubNavContainer'
import {getLinks} from '@/components/Nav/utils'
import {showFlagsPanelAtom} from '@/components/UserFlagsPanel/atoms'
import useFlags from '@/hooks/useFlags'
import {useAuth} from '@/providers/AuthProvider'
import {usePermissions} from '@/providers/PermissionAndNavProvider'

import {Nav_data$data} from '@/__generated__/Nav_data.graphql'

import CompanyName from './CompanyName'
import SubNavList from './SubNavList'

export type Props = {
    data: Nav_data$data
}

const NavContainer = styled('div')<{isSwitched: boolean}>(({theme, isSwitched}) => ({
    backgroundColor: theme.palette.black,
    ...(isSwitched && {
        backgroundColor: theme.palette.primary[800],
    }),
    fontFamily: "'Roboto', sans-serif",
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 48px',
}))

const DesktopNavReal = ({data}: Props) => {
    const intl = useIntl()
    const auth = useAuth()
    const perms = usePermissions()
    const flags = useFlags()
    const location = useLocation()

    const {langSwitcher} = flags
    const links = getLinks(intl, perms, flags)

    const [locale, setLocale] = useAtom(localeAtom, store)

    const defaultActiveLink = useMemo(
        () => links.find(({baseUrl, to}) => location.pathname.startsWith(baseUrl || to)),
        [links, location.pathname],
    )

    const ref = useRef<null | HTMLAnchorElement>(null)
    const [activeLink, setActiveLink] = useState(defaultActiveLink)
    // eslint-disable-next-line no-implicit-coercion -- FIXME
    const isSwitched = !!auth.effectiveOrganization
    const hasFlagPanel = perms.navData?.isSuperuser || perms.navData?.isStaff
    const [isShowingFlagsPanel, setShowFlags] = useAtom(showFlagsPanelAtom)

    return (
        <nav onMouseLeave={() => setActiveLink(defaultActiveLink)}>
            <NavContainer isSwitched={isSwitched}>
                <div>
                    <div className="Nav-links">
                        <RouterLink className="logo no-right-margin" role="tab" to="/">
                            <Logo />
                        </RouterLink>
                        <CompanyName>{perms.orgName}</CompanyName>
                    </div>
                </div>
                <Box alignItems="center" display="flex" flexDirection="row">
                    <Tooltip title={<FormattedMessage defaultMessage="Help Content/User Guides" />}>
                        <IconButton href="https://docs.minehub.app/">
                            <QuestionOutlinedIcon fontSize="large" />
                        </IconButton>
                    </Tooltip>
                    {langSwitcher ? (
                        <div>
                            <Select
                                defaultValue={locale}
                                onChange={(evt) => {
                                    if (evt.target?.value) {
                                        setLocale(evt.target.value as string)
                                    }
                                }}
                                style={{background: 'white'}}>
                                <MenuItem value="en-US">{languages['en-US'].label}</MenuItem>
                                <MenuItem value="pt">{languages.pt.label}</MenuItem>
                            </Select>
                        </div>
                    ) : null}
                    {hasFlagPanel ? (
                        <IconButton onClick={() => setShowFlags((prev) => !prev)}>
                            <FlagOutlinedIcon color={isShowingFlagsPanel ? 'info' : 'inherit'} fontSize="large" />
                        </IconButton>
                    ) : null}
                    {data ? <Menu data={data} /> : null}
                </Box>
                <Box sx={{flexBasis: '100%'}}>
                    <div className="Nav-links">
                        {map(links, (link, index) => (
                            <NavItem
                                item={link}
                                key={link.to}
                                onMouseEnter={() => setActiveLink(link)}
                                ref={activeLink?.to === link.to ? ref : null}
                                sx={index === 0 ? {marginLeft: 0} : undefined}
                                to={link.to}
                                {...(link.baseUrl &&
                                    location.pathname.startsWith(link.baseUrl) && {className: 'active'})}
                            />
                        ))}
                    </div>
                </Box>
            </NavContainer>
            <SubNavContainer data-chromatic="ignore">
                <SwitchTransition mode="out-in">
                    <Fade key={activeLink?.to}>
                        <SubNavList items={activeLink?.subItems ?? []} navRef={ref} />
                    </Fade>
                </SwitchTransition>
            </SubNavContainer>
        </nav>
    )
}

const Fallback = ({children}: {children?: React.ReactNode}) => (
    <nav>
        <Box
            className="Nav Nav-Desktop"
            sx={{
                backgroundColor: (theme) => theme.palette.black,
            }}>
            <div className="Nav-left">
                <div className="Nav-links">
                    <RouterLink className="logo" role="tab" to="/">
                        <Logo />
                    </RouterLink>
                    {children}
                </div>
            </div>
        </Box>
    </nav>
)

type DesktopNavDeciderProps = {
    data: Nav_data$data | null
}

const DesktopNavDecider = ({data}: DesktopNavDeciderProps) => {
    if (isNil(data)) {
        return <Fallback />
    }

    return (
        <Suspense
            fallback={
                <Fallback>
                    <Skeleton height="100%" sx={{bgcolor: 'grey.800'}} width={200} />
                </Fallback>
            }>
            <DesktopNavReal data={data} />
        </Suspense>
    )
}

export default DesktopNavDecider
