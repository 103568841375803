import MUISkeleton, {SkeletonProps as MUISkeletonProps} from '@mui/material/Skeleton'
import cn from 'classnames'

export type SkeletonProps = MUISkeletonProps

/**
 * Display a placeholder preview of your content before the data gets loaded to reduce load-time frustration.
 */
export const Skeleton = ({className, ...props}: SkeletonProps) => (
    <MUISkeleton className={cn('Skeleton', className)} {...props} />
)

export default Skeleton
