import {SvgIcon} from '@mui/material'
import cn from 'classnames'

import {ConveyanceSVGProps} from './props'

export const Vessel = ({background, fill, fontSize = 'medium', className}: ConveyanceSVGProps) => (
    <SvgIcon
        className={cn('icon icon-vessel', className, {
            'with-background': background,
        })}
        fill={fill}
        fontSize={fontSize}
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.33 11.997c1.243.853 2.636 1.355 4.23 1.355V16H.5v-2.648l.323-.007c1.492-.062 2.771-.552 3.919-1.348C5.834 13 6.938 13.49 8.03 13.49c1.117 0 2.221-.515 3.3-1.493zM8.237 0c1.594.008 1.802.119 1.802.564l-.001.376h2.036c.845.007.959.077.974.843l.002.134v3.916l1.858.742-1.067 5.033c-.916-.277-1.757-.779-2.635-1.544-1.117 1.267-2.146 1.908-3.175 1.908-1.03 0-2.058-.64-3.175-1.908-.879.765-1.72 1.267-2.636 1.544L1.153 6.575l1.856-.741.001-3.639c0-1.154 0-1.247.977-1.254L6.021.94V.564c0-.445.21-.556 1.802-.564zm3.559 2.195h-7.53l-.001 3.137L8.03 3.827l3.764 1.505V2.195z"
            fillRule="evenodd"
            transform="translate(-822 -7266) translate(822 7266)"
        />
    </SvgIcon>
)
