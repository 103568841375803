/**
 * @generated SignedSource<<47af4d651333561c11ab615f826aac55>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ShipmentLinkQuery$variables = {
  shipmentPk: number;
};
export type ShipmentLinkQuery$data = {
  readonly delivery: {
    readonly billOfLading: {
      readonly buyerOrg: {
        readonly pk: number;
      };
    };
    readonly locationConveyance: {
      readonly location: {
        readonly pk: number;
      };
    };
  } | null;
};
export type ShipmentLinkQuery = {
  response: ShipmentLinkQuery$data;
  variables: ShipmentLinkQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "shipmentPk"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "pk",
    "variableName": "shipmentPk"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pk",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  (v2/*: any*/),
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ShipmentLinkQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Delivery",
        "kind": "LinkedField",
        "name": "delivery",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LocationConveyance",
            "kind": "LinkedField",
            "name": "locationConveyance",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Location",
                "kind": "LinkedField",
                "name": "location",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BillOfLading",
            "kind": "LinkedField",
            "name": "billOfLading",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "buyerOrg",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ShipmentLinkQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Delivery",
        "kind": "LinkedField",
        "name": "delivery",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LocationConveyance",
            "kind": "LinkedField",
            "name": "locationConveyance",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Location",
                "kind": "LinkedField",
                "name": "location",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BillOfLading",
            "kind": "LinkedField",
            "name": "billOfLading",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "buyerOrg",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8cbd641986e143bce77ed2d21a2abea6",
    "id": null,
    "metadata": {},
    "name": "ShipmentLinkQuery",
    "operationKind": "query",
    "text": "query ShipmentLinkQuery(\n  $shipmentPk: Int!\n) {\n  delivery(pk: $shipmentPk) {\n    locationConveyance {\n      location {\n        pk\n        id\n      }\n      id\n    }\n    billOfLading {\n      buyerOrg {\n        pk\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "cac1ac64a652838b44f3a75a48fbef7f";

export default node;
