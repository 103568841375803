import {Components, Theme} from '@mui/material'
import {ComponentsOverrides} from '@mui/material/styles'

export const MuiAutocomplete: Components<Theme>['MuiAutocomplete'] = {
    defaultProps: {
        disableClearable: true,
    },
    styleOverrides: {
        root: ({theme}) => ({
            '& .MuiOutlinedInput-root': {
                gap: theme.spacing(1),

                // remove padding on the input containers
                padding: 0,
                '&.MuiInputBase-sizeSmall': {
                    padding: 0,
                },
                [`.MuiAutocomplete-input.MuiOutlinedInput-input`]: {
                    // Adds spacing when MultiSelect has tags selected, 7px is the the original 12px minus
                    // left padding on the container.
                    padding: '0 8px 0 7px',
                    '&:first-of-type': {
                        padding: '10px 8px 10px 12px',
                    },
                },
                [`&.MuiInputBase-sizeSmall .MuiAutocomplete-input.MuiOutlinedInput-input`]: {
                    padding: '0 8px 0 7px',
                    '&:first-of-type': {
                        padding: '7px 12px',
                    },
                },
            },
            // Multiple select is based on Autocomplete
            '.MuiAutocomplete-tag': {
                margin: 0,
            },
            '&.MultiSelect': {
                '& .MuiChip-deleteIcon': {
                    color: theme.palette.grey70,
                },
            },
        }),
        listbox: ({theme}) => ({
            '& .MuiAutocomplete-option': {
                // if you change this, make sure you align `MuiMenuItem` so
                // that MuiSelect-options appear match. The goal is to have a single lined
                // to be 40px heigh.
                padding: theme.spacing(1.25, 2, 1.25, 2),
            },
        }),
        paper: ({theme}) => ({
            boxShadow: theme.shadows[12],
        }),
    } as ComponentsOverrides<Theme>['MuiAutocomplete'],
}

export default MuiAutocomplete
