import {useState} from 'react'
import {FieldPath, FieldValues} from 'react-hook-form'

import IconButton from '@waybridge/wui/IconButton'
import {EyeIcon, EyeOffIcon} from '@waybridge/wui/Icons'
import InputAdornment from '@waybridge/wui/InputAdornment'
import TextField, {Props} from '@waybridge/wui/TextField/Controller'

const PasswordField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
    props: Props<TFieldValues, TName>,
) => {
    const [isHidden, setIsHidden] = useState(true)

    const handleTogglePassword = () => {
        setIsHidden(!isHidden)
    }

    return (
        <TextField<TFieldValues, TName>
            {...props}
            InputProps={{
                // eslint-disable-next-line react/destructuring-assignment -- FIXME
                ...props.InputProps,
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="Toggle password visibility"
                            edge="end"
                            onClick={handleTogglePassword}
                            onMouseDown={handleTogglePassword}>
                            {isHidden ? <EyeIcon /> : <EyeOffIcon />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            type={isHidden ? 'password' : 'text'}
        />
    )
}

export default PasswordField
