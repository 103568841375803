import {Theme} from '@mui/material/styles'
import {useForm} from 'react-hook-form'
import {FormattedMessage, useIntl} from 'react-intl'

import Button from '@waybridge/wui/Button'
import ModalPage, {ModalActions, ModalForm, ModalTitle} from '@waybridge/wui/ModalPage/ModalPage'
import RouterLink from '@waybridge/wui/RouterLink'
import TextField from '@waybridge/wui/TextField/Controller'

import {getPasswordResetPath} from '@/pages/routes/paths'
import {useAuth} from '@/providers/AuthProvider'
import {titleCase} from '@/utils/formatting'

import PasswordField from './PasswordField'

interface LoginData {
    email: string
    password: string
}

const MinehubLogin = () => {
    const auth = useAuth()
    const intl = useIntl()

    const {
        control,
        getValues,
        handleSubmit,
        setError,
        formState: {errors},
    } = useForm({
        shouldUnregister: false,
        defaultValues: {
            email: '',
            password: '',
        },
    })

    const onSubmit = (data: LoginData) => {
        auth.login(data.email, data.password)
            .then(() => {
                window.location.reload()
            })
            .catch((err) => {
                let errorMessage = ''
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME
                if (err?.status === 401) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- FIXME
                    const rawError = err?.text && JSON.parse(err.text)
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, eqeqeq -- FIXME
                    if (rawError?.code == 'account_locked') {
                        errorMessage = intl.formatMessage({
                            defaultMessage:
                                'Your account has been locked. Please try again later or contact MineHub support.',
                        })
                    } else {
                        errorMessage = intl.formatMessage({defaultMessage: 'The credentials are not correct'})
                    }
                } else {
                    console.error('Login', err)
                    errorMessage = intl.formatMessage({
                        defaultMessage: 'There was an error connecting to the server. Please try again',
                    })
                }

                setError('password', {
                    type: 'manual',
                    message: errorMessage,
                })
            })
    }
    return (
        <ModalPage>
            <ModalTitle role="heading" variant="h4">
                <FormattedMessage defaultMessage="Sign in" />
            </ModalTitle>
            <ModalForm onSubmit={handleSubmit(onSubmit)}>
                <TextField
                    InputLabelProps={{required: true}}
                    control={control}
                    helperText={titleCase(errors.email?.message ?? '')}
                    label={<FormattedMessage defaultMessage="Email address" />}
                    name="email"
                    rules={{
                        required: true,
                        minLength: 4,
                    }}
                    sx={{mx: '20px'}}
                />
                <PasswordField
                    InputLabelProps={{required: true}}
                    control={control}
                    helperText={errors.password?.message ?? ''}
                    label={<FormattedMessage defaultMessage="Password" />}
                    name="password"
                    rules={{
                        required: true,
                        minLength: 4,
                    }}
                    sx={{mx: '20px'}}
                />
                <RouterLink
                    sx={(theme: Theme) => ({
                        textAlign: 'right',
                        paddingRight: '20px',
                        color: theme.palette.secondary.main,
                    })}
                    to={`${getPasswordResetPath()}?email=${getValues('email') ?? ''}`}>
                    <FormattedMessage defaultMessage="Forgot your password?" />
                </RouterLink>
                <ModalActions>
                    <Button type="submit" variant="contained">
                        <FormattedMessage defaultMessage="Sign in" />
                    </Button>
                </ModalActions>
            </ModalForm>
        </ModalPage>
    )
}

export default MinehubLogin
