import {round} from 'lodash'
import {DateTime} from 'luxon'
import {FormatNumberOptions, IntlShape} from 'react-intl'

import {compareDates} from './dates'

/**
 * Adds a 0 in front of single digit numbers.
 */
export const padNumber = (num: number): string => (num < 10 ? `0${num}` : `${num}`)

/**
 * Returns the string with the first letter capitalized
 */
export const titleCase = (str?: string): string => {
    if (!str) {
        return ''
    }
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export const roundToDigits = (value: number, numDigits: number) => {
    const multiplier = 10 ** numDigits
    return Math.round(value * multiplier) / multiplier
}

// eslint-disable-next-line prefer-named-capture-group, require-unicode-regexp -- FIXME
export const validateEmail = (email: string): boolean => /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(email)

export const formatDateString = (intl: IntlShape, date: string | null, showDay = true, showFullYear = true) => {
    if (!date) {
        return null
    }
    const parsedDate = DateTime.fromISO(date)
    if (!parsedDate.isValid) {
        return null
    }
    // eslint-disable-next-line @typescript-eslint/no-use-before-define -- FIXME
    return formatDate(intl, parsedDate.toJSDate(), showDay, false, showFullYear)
}

export const formatDate = (
    intl: IntlShape,
    m: Date,
    showDay = false,
    showWeekday = false,
    showFullYear = true,
): string => {
    const formatOptions: Intl.DateTimeFormatOptions = {year: 'numeric', month: 'short'}
    if (showDay) {
        formatOptions.day = 'numeric'
    }
    if (showWeekday) {
        formatOptions.weekday = 'long'
    }
    if (!showFullYear) {
        formatOptions.year = '2-digit'
    }
    const f = new Intl.DateTimeFormat(intl.locale, formatOptions)
    return f.format(m)
}

interface FormatDateOptions {
    forceShowDate?: boolean
    singleMonthIsRange?: boolean
    asString?: boolean
    singleMonth?: boolean
    excludeYear?: boolean
}

export const formatDateRange = (
    intl: IntlShape,
    start: string,
    end: string,
    options: FormatDateOptions = {
        forceShowDate: false,
        singleMonthIsRange: false,
        singleMonth: false,
        excludeYear: false,
    },
) => {
    const {isRange, isIncludeDate, sameMonth} = compareDates(start, end, options.singleMonthIsRange)
    const formatOptions: Intl.DateTimeFormatOptions = {month: 'short'}
    if (!options.excludeYear) {
        formatOptions.year = 'numeric'
    }
    if (isIncludeDate || options.forceShowDate) {
        formatOptions.day = 'numeric'
    }
    const startDisplay = DateTime.fromISO(start).setLocale(intl.locale).toLocaleString(formatOptions)

    let endDisplay = ''
    if (options.singleMonth && sameMonth) {
        endDisplay = `${DateTime.fromISO(end).setLocale(intl.locale).day}`
    } else {
        endDisplay = DateTime.fromISO(end).setLocale(intl.locale).toLocaleString(formatOptions)
    }

    if (isRange) {
        return `${startDisplay} – ${endDisplay}`
        // eslint-disable-next-line curly, no-else-return -- FIXME
    } else return startDisplay
}

export const formatMonthDate = (intl: IntlShape, date: string) =>
    DateTime.fromISO(date).setLocale(intl.locale).toLocaleString({month: 'short', day: 'numeric'})

export interface FormatPriceOptions {
    sign?: 'always' | 'disabled' | undefined
    locale?: string
    currency?: string
    maxDecimals?: number
    minDecimals?: number
}

export const formatPrice = (intl: IntlShape, price: number, options: Partial<FormatPriceOptions> = {}): string => {
    const opts: FormatPriceOptions = {
        sign: undefined,
        locale: 'us',
        currency: undefined,
        maxDecimals: 4,
        minDecimals: 2,
        ...options,
    }

    // eslint-disable-next-line eqeqeq -- FIXME
    if (opts?.sign == 'disabled') {
        // eslint-disable-next-line no-param-reassign -- FIXME
        price = Math.abs(price)
    }

    const displayPrice = round(price, opts.maxDecimals)

    const numOptions: FormatNumberOptions = {
        maximumFractionDigits: opts.maxDecimals,
        minimumFractionDigits: opts.minDecimals,
        style: opts.currency ? 'currency' : undefined,
        currency: opts.currency,
    }

    const res = intl.formatNumber(displayPrice, numOptions)
    // eslint-disable-next-line eqeqeq -- FIXME
    return (opts.sign == 'always' && price >= 0 ? '+' : '') + res
}

export type NumberParts = {
    numerals: string[]
    group: string
    minusSign: string
    decimal: string
}

export const getNumberParts = (intl: IntlShape): NumberParts => {
    const numerals = [...intl.formatNumber(9876543210, {useGrouping: false})].reverse()
    const parts = intl.formatNumberToParts(-1234567890.123)
    const group = parts.find((d) => d.type === 'group')?.value || ''
    const minusSign = parts.find((d) => d.type === 'minusSign')?.value || ''
    const decimal = parts.find((d) => d.type === 'decimal')?.value || ''

    return {
        numerals,
        group,
        minusSign,
        decimal,
    }
}

export const getFullName = (firstName?: string, lastName?: string) =>
    firstName && lastName ? `${firstName} ${lastName}` : firstName ? firstName : lastName ? lastName : undefined

// Format number of bytes using base 10: e.g. 1775171 -> "1.78 MB"
export const formatBytes = (bytes: number): string => {
    // eslint-disable-next-line curly -- FIXME
    if (bytes <= 0) return '0 Bytes'

    const symbols = ['Bytes', 'KB', 'MB', 'GB']
    const i = Math.floor(Math.log(bytes) / Math.log(1000))

    // show decimals only from MB on
    const decimals = i > 1 ? 2 : 0

    const result = parseFloat((bytes / Math.pow(1000, i)).toFixed(decimals))

    return `${result} ${symbols[i]}`
}

// formats date to 'd MMM, yyyy'
export const formatDateDMY = (date?: string) => {
    if (!date) {
        return null
    }

    return DateTime.fromISO(date).toFormat('d MMM, yyyy')
}
