import cn from 'classnames'
import {omit} from 'lodash'
import {toast, ToastContainer, ToastTransitionProps} from 'react-toastify'
import {Transition} from 'react-transition-group'

import {Check} from '@/components/Icon'

import 'react-toastify/dist/ReactToastify.css'
import './Snackbar.less'

export interface SnackbarOptions {
    onClose?: () => void
    autoClose?: number
}

// toast transition for tests
const Open = ({children, nodeRef, done, ...props}: ToastTransitionProps) => (
    <Transition
        {...omit(props, 'preventExitTransition')}
        nodeRef={nodeRef}
        onExit={done}
        timeout={{enter: 0, exit: 0}}
        unmountOnExit>
        {children}
    </Transition>
)

// toast container for tests
export const TestSnackbarContainer = () => <ToastContainer transition={Open} />

export const SnackbarContainer = () => <ToastContainer />

const defaultSnackId = 'a-snackbar-id'

/**
 * @deprecated Use WUI alternative
 */
export const renderSnackbar = (component: any, _id: string | null = defaultSnackId, options: SnackbarOptions = {}) => {
    const {autoClose = 3000, onClose} = options

    const id = _id ?? defaultSnackId

    // Reset the timer if this is a re-render of the same snackbar
    if (toast.isActive(id)) {
        toast.update(id)
    }

    toast(component, {
        position: 'bottom-left',
        className: 'base-snackbar',
        autoClose,
        closeButton: false,
        hideProgressBar: true,
        toastId: id,
        onClose,
    })
}

type SnackbarVariants = 'update' | 'update-light' | 'error' | 'success' | 'warning'
type Props = {
    /** The content to display in the snackbar */
    children: React.ReactNode
    /** An icon to display to the left of the text */
    icon?: any
    /** An button to display to the right of the text */
    button?: any
    /** Class name for custom styling */
    className?: string
    /** The variant of the snackbar (ex. update or error) */
    variant?: SnackbarVariants
    /** Small snackbar has a small fixed width */
    small?: boolean
}

/**
 * Snackbar is a component for rendering a transient notification somewhere in the visible screen.
 *
 * Right now it will always render at the bottom left of the screen.
 *
 * @deprecated Use WUI alternative
 */
export const Snackbar = ({children, icon, button, className, variant = 'update', small}: Props) => (
    <div aria-label="snackbar" className={cn('snackbar', className, {[`v-${variant}`]: variant, small})}>
        <div className="snackbar-left">
            {/* eslint-disable-next-line react/jsx-no-leaked-render -- FIXME */}
            {icon && <div className="snackbar-icon">{icon}</div>}
            <div>{children}</div>
        </div>
        {/* eslint-disable-next-line react/jsx-no-leaked-render -- FIXME */}
        {button && button}
    </div>
)

/**
 * @deprecated Use WUI alternative
 */
export const successSnackbar = (message: React.ReactNode, id?: string) => {
    renderSnackbar(
        <Snackbar icon={<Check />} variant="success">
            {message}
        </Snackbar>,
        // eslint-disable-next-line no-eq-null, eqeqeq -- FIXME
        id != null ? `success-snackbar-${id}` : null,
    )
}
