import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon'

export const Vessel = (props: SvgIconProps) => (
    <SvgIcon
        {...props}
        fill="currentColor"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg">
        <path
            clipRule="evenodd"
            d="M16.6276 3.49036H5.15713V7.31386H6.43157V4.76489H15.3531V7.31386H16.6276V3.49036ZM20.451 7.31387H1.3335V11.1374H2.60815V8.58841H10.2552V11.1374H11.5294V8.58843H19.1764V11.1374H20.451V7.31387ZM23 11.1374H1.3335V17.5099V17.5099L1.33355 17.5099H3.56073C4.10942 17.2819 4.59848 16.7186 5.00156 16.2542C5.05492 16.1928 5.10676 16.133 5.15704 16.076C5.85483 16.879 6.87762 17.5099 8.02467 17.5099C9.17172 17.5099 10.1945 16.879 10.8923 16.076C11.5901 16.879 12.6129 17.5099 13.7599 17.5099C14.907 17.5099 15.9202 16.879 16.6275 16.076C16.6778 16.133 16.7297 16.1928 16.783 16.2542C17.1861 16.7186 17.6752 17.2819 18.2239 17.5099H20.4509L20.451 17.5099L20.451 17.5099H20.451V17.5098L23 11.1374ZM20.451 19.4216V21.3334H19.4952C18.5106 21.3334 17.5356 21.0944 16.6275 20.6164C14.8209 21.5723 12.6989 21.5723 10.8923 20.6164C9.08569 21.5723 6.96365 21.5723 5.15704 20.6164C4.24896 21.0944 3.27397 21.3334 2.28942 21.3334H1.33354V19.4216H2.28942C3.28353 19.4216 4.2872 19.087 5.15704 18.4657C6.9063 19.7084 9.14304 19.7084 10.8923 18.4657C12.6415 19.7084 14.8783 19.7084 16.6275 18.4657C17.507 19.087 18.5011 19.4216 19.4952 19.4216H20.451Z"
            fillRule="evenodd"
        />
    </SvgIcon>
)

export default Vessel
