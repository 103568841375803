import {lazy} from 'react'
import {createRoutesFromElements, Outlet, Route, Routes} from 'react-router'
import {createBrowserRouter, Navigate} from 'react-router-dom'

import {FullHeightSuspense} from '@waybridge/wui/Suspense'

import {BaseWrapper} from '@/app/Base'
import ErrorPage from '@/components/Error/ErrorPage'
import Login from '@/components/Login'
import useFlags from '@/hooks/useFlags'
import Base from '@/pages/Base'
import PasswordManager from '@/pages/PasswordManager/PasswordManager'
import {ShipmentLink} from '@/pages/ShipmentLink/ShipmentLink'
import {TradeListType} from '@/pages/TradeList/v2/types'
import {ProtectedRoute} from '@/providers/AuthProvider'
import PermissionAndNavProvider, {usePermissions} from '@/providers/PermissionAndNavProvider'
import {RelayProvider} from '@/providers/RelayProvider'

import * as paths from './paths'
import {getTopPagePath, usePosthogPageView} from './utils'

const OrgSettings = lazy(() => import('@/pages/OrgSettings'))
const OrgSettingsMain = lazy(() => import('@/pages/OrgSettings/Main'))
const OrgSettingsUser = lazy(() => import('@/pages/OrgSettings/User'))
const OrgSettingsLocation = lazy(() => import('@/pages/OrgSettings/Location'))
const BookedAppointments = lazy(() => import('@/pages/BookedAppointments'))
const BookTrade = lazy(() => import('@/pages/BookTrade'))
const ConsignmentSchedules = lazy(() => import('@/pages/ConsignmentSchedules'))
const DeliveryAppointments = lazy(() => import('@/pages/DeliveryAppointments'))
const EditShipmentContainer = lazy(() => import('@/pages/_legacy/Shipments/EditShipment'))
const InventoryAccountDetails = lazy(() => import('@/pages/InventoryAccountDetails/InventoryAccountDetails'))
const InventoryAccountPage = lazy(() => import('@/pages/InventoryAccountDetails/InventoryAccountPage'))
const InventoryAccountReleases = lazy(
    () => import('@/pages/InventoryAccountDetails/Releases/InventoryAccountReleasesWrapper'),
)
const InventoryAccountShipments = lazy(() => import('@/pages/InventoryAccountShipments/InitialPage'))
const InventoryAccountTransfers = lazy(() => import('@/pages/Consignment/InternalTransfers/InternalTransfersV2Wrapper'))
const InventoryAccountTransferCreate = lazy(
    () => import('@/pages/Consignment/InternalTransfers/Create/CreateV2Wrapper'),
)
const InventoryAccountTransferEdit = lazy(() => import('@/pages/Consignment/InternalTransfers/Edit/EditV2Wrapper'))
const InventoryActivities = lazy(() => import('@/pages/InventoryActivities/InitialPage'))
const InventoryManager = lazy(() => import('@/pages/InventoryManager'))
const InventorySearch = lazy(() => import('@/pages/InventorySearch'))
const InvoiceList = lazy(() => import('@/pages/InvoiceList'))
const NewInventoryAccount = lazy(() => import('@/pages/InventoryAccountDetails/NewInventoryAccount'))
const NewPassword = lazy(() => import('@/pages/PasswordManager/NewPassword'))
const NewShipment = lazy(() => import('@/pages/ShipmentDetails').then((module) => ({default: module.NewShipment})))
const PhysicalPosition = lazy(() => import('@/pages/PhysicalPosition'))
const Register = lazy(() => import('@/pages/PasswordManager/Register'))
const ResetPassword = lazy(() => import('@/pages/PasswordManager/ResetPassword'))
const SchedulingMoved = lazy(() => import('@/pages/Scheduling/Moved'))
const ShipmentDetails = lazy(() => import('@/pages/ShipmentDetails'))
const Shipments = lazy(() => import('@/pages/ShipmentsList'))
const StockFlow = lazy(() => import('@/pages/StockFlow'))
const TradeDetails = lazy(() => import('@/pages/TradeDetails'))
const TradeDetailsRedirect = lazy(() => import('@/pages/TradeDetails/Redirect'))
const Trades = lazy(() => import('@/pages/Trades'))
const TradeListV2 = lazy(() => import('@/pages/TradeList/v2/InitialPage'))
const TradeManager = lazy(() => import('@/pages/TradeManager'))
const TradeSchedules = lazy(() => import('@/pages/TradeSchedules'))
const TradeShipments = lazy(() => import('@/pages/TradeShipments'))
const TradeQuotas = lazy(() => import('@/pages/TradeQuotas'))
const UserProfile = lazy(() => import('@/pages/UserProfile'))

/**
 * This needs to run in the context of PermissionAndNavProvider
 *
 * NOTE: You cannot use LaunchDarkly flags to do the first redirect, because the flags
 *       are not available immediately when the app/page loads.
 *       (Ask in #frontend-donuts for an alternative solution)
 */
const TopRoute = () => {
    const {roleCodes, isPrincipal} = usePermissions()
    return <Navigate replace to={getTopPagePath(roleCodes, isPrincipal)} />
}

export const NavRoutes = () => {
    const {showAllTradesTab} = useFlags()
    return (
        <FullHeightSuspense>
            <PermissionAndNavProvider>
                <Routes>
                    <Route element={<Base />} errorElement={<ErrorPage />} path="*">
                        <Route element={<Navigate replace to="/" />} path={paths.loginPath} />
                        <Route element={<UserProfile />} path={`${paths.profilePath}/*`} />
                        <Route element={<InventoryManager />} path={'consignment/*'} />
                        {/* Inventory manager */}
                        <Route path={paths.inventoryPath}>
                            <Route element={<Navigate replace to={paths.manager} />} index />
                            <Route element={<InventoryManager />} path={paths.manager} />
                            <Route element={<InventoryManager showClosedAccounts />} path="closed" />
                            <Route element={<InventorySearch />} path="search" />
                            <Route element={<NewInventoryAccount />} path="accounts/new" />
                        </Route>
                        {/*Inventory Account Info */}
                        <Route element={<InventoryAccountPage />} path={`${paths.inventoryPath}/accounts/:accountId/*`}>
                            <Route element={<InventoryAccountDetails />} path="details" />
                            <Route element={<InventoryAccountShipments />} path="shipments/*" />
                            <Route element={<InventoryAccountReleases />} path="releases" />
                            <Route element={<ConsignmentSchedules />} path="schedules" />
                            <Route element={<InventoryAccountTransfers />} path="transfers" />
                            <Route element={<InventoryAccountTransferCreate />} path="transfers/create" />
                            <Route element={<InventoryAccountTransferEdit />} path="transfers/edit" />
                            <Route element={<InventoryActivities />} path="activities/*" />
                            <Route element={<Navigate replace to="details" />} path="*" />
                        </Route>
                        <Route element={<OrgSettings />} path={paths.orgSettingsPath}>
                            <Route element={<OrgSettingsMain />} index />
                            <Route element={<OrgSettingsUser />} path="users/:pk" />
                            <Route element={<OrgSettingsLocation />} path="locations/:pk" />
                            <Route element={<Navigate replace to={paths.orgSettingsPath} />} path="*" />
                        </Route>
                        <Route path={paths.appointmentsPath}>
                            <Route element={<DeliveryAppointments />} index />
                            <Route element={<BookedAppointments />} path={paths.bookedAppointmentsPath} />
                        </Route>
                        <Route path={paths.tradesPath}>
                            <Route element={<Navigate replace to={paths.manager} />} index />
                            <Route element={<TradeSchedules />} path=":tradePk/schedules/*" />
                            <Route element={<TradeShipments />} path=":tradePk/shipments" />
                            <Route element={<TradeDetails />} path=":tradePk/details" />
                            <Route element={<TradeQuotas />} path=":tradePk/quotas" />
                            {showAllTradesTab ? (
                                <Route element={<Trades />} path={`${paths.allTradesPath}/*`} />
                            ) : (
                                <>
                                    <Route
                                        element={<TradeListV2 listType={TradeListType.Live} />}
                                        path={`${paths.tradeListPath}/*`}
                                    />
                                    <Route element={<TradeListV2 listType={TradeListType.Closed} />} path="closed/*" />
                                    <Route element={<TradeListV2 listType={TradeListType.Draft} />} path="drafts/*" />
                                </>
                            )}
                            <Route element={<TradeManager />} path={`${paths.manager}/*`} />
                            <Route element={<BookTrade />} path={`${paths.editTradePath}/:tradePk/draft`} />
                            <Route element={<TradeDetailsRedirect />} path=":tradePk/*" />
                        </Route>
                        <Route element={<SchedulingMoved />} path="scheduling/*" />
                        {/** Shipment details (edit/create) */}
                        <Route element={<NewShipment />} path={paths.newShipmentsPath} />
                        <Route element={<ShipmentLink />} path={`${paths.shipmentsLink}/:shipmentPk`} />
                        <Route element={<ShipmentDetails />} path={`${paths.shipmentsPath}/:shipmentPk/details`} />
                        {/** Shipmentslist */}
                        <Route element={<Shipments />} path={`${paths.shipmentsPath}/*`} />
                        {/** Legacy shipments */}
                        <Route path={paths.supplierShipmentsPath}>
                            <Route element={<EditShipmentContainer type="supplier" />} path="edit/:number" />
                            <Route element={<Navigate replace to={`/${paths.shipmentsPath}`} />} index />
                            <Route element={<Navigate replace to={`/${paths.shipmentsPath}`} />} path="*" />
                        </Route>
                        <Route path={paths.buyerShipmentsPath}>
                            <Route element={<EditShipmentContainer type="buyer" />} path=":locationPk/edit/:number" />
                            <Route element={<Navigate replace to={`/${paths.shipmentsPath}`} />} index />
                            <Route element={<Navigate replace to={`/${paths.shipmentsPath}`} />} path="*" />
                        </Route>
                        <Route element={<StockFlow />} path={`${paths.stockFlowPath}/*`} />
                        <Route element={<PhysicalPosition />} path={`${paths.physicalPositionPath}/*`} />
                        <Route element={<InvoiceList />} path={`${paths.invoices}/*`} />
                        <Route element={<TopRoute />} path="*" />
                    </Route>
                </Routes>
            </PermissionAndNavProvider>
        </FullHeightSuspense>
    )
}

const PageWrapper = () => {
    usePosthogPageView()
    return (
        <BaseWrapper>
            <Outlet />
        </BaseWrapper>
    )
}

export const routes = createRoutesFromElements(
    <Route element={<PageWrapper />} errorElement={<ErrorPage />} path="/">
        <Route element={<PasswordManager />} path={`${paths.passwordPath}`}>
            <Route element={<Navigate replace to={paths.resetPasswordPath} />} index />
            <Route element={<ResetPassword />} path={paths.resetPasswordPath} />
            <Route element={<NewPassword />} path={paths.newPasswordPath} />
            <Route element={<Register />} path={paths.registerPath} />
        </Route>
        <Route
            element={
                <RelayProvider>
                    <ProtectedRoute login={<Login />}>
                        <NavRoutes />
                    </ProtectedRoute>
                </RelayProvider>
            }
            index
        />
        <Route
            element={
                <RelayProvider>
                    <ProtectedRoute login={<Login />}>
                        <NavRoutes />
                    </ProtectedRoute>
                </RelayProvider>
            }
            errorElement={<ErrorPage />}
            path="*"
        />
    </Route>,
)

export const router = createBrowserRouter(routes)

export const AllRoutes = () => {
    usePosthogPageView()

    return (
        <Routes>
            <Route element={<PasswordManager />} path={`/${paths.passwordPath}`}>
                <Route element={<Navigate replace to={paths.resetPasswordPath} />} index />
                <Route element={<ResetPassword />} path={paths.resetPasswordPath} />
                <Route element={<NewPassword />} path={paths.newPasswordPath} />
                <Route element={<Register />} path={paths.registerPath} />
            </Route>
            <Route
                element={
                    <RelayProvider>
                        <ProtectedRoute login={<Login />}>
                            <NavRoutes />
                        </ProtectedRoute>
                    </RelayProvider>
                }
                path="/*"
            />
        </Routes>
    )
}

export default AllRoutes
