import {SvgIcon} from '@mui/material'
import cn from 'classnames'

import {ConveyanceSVGProps} from './props'

export const Rail = ({background, fill, fontSize = 'medium', className}: ConveyanceSVGProps) => (
    <SvgIcon
        className={cn('icon icon-rail', className, {
            'with-background': background,
        })}
        fill={fill}
        fontSize={fontSize}
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.333 11.667a1.333 1.333 0 110 2.666 1.333 1.333 0 010-2.666zm3.334 0a1.333 1.333 0 110 2.666 1.333 1.333 0 010-2.666zm3.333 0a1.333 1.333 0 110 2.666 1.333 1.333 0 010-2.666zm4-8V5h-.667v8H13a1.998 1.998 0 00-3.667-1.103 1.998 1.998 0 00-3.333 0A1.998 1.998 0 004.333 11c-.904 0-1.658.605-1.906 1.427L1 11V9l2-2-.667-2.667h4L5.667 7H9V5h-.667V3.667H15zm-2 2h-2.667v2H13v-2zM6.333 3v.667h-4V3h4z"
            fillRule="evenodd"
        />
    </SvgIcon>
)
