import {TradeListV2$data} from '@/__generated__/TradeListV2.graphql'
import {TradeListV2FiltersQuery$data} from '@/__generated__/TradeListV2FiltersQuery.graphql'
import {TradeStatusNew} from '@/__generated__/types'

export type {
    MetalsTradeSortField,
    OrderDirectionEnum,
    TradeFulfillmentType,
    TradeType,
} from '@/__generated__/TradeListV2Query.graphql'
export {TradeStatusNew} from '@/__generated__/types'

type TradeList = TradeListV2$data['metalsTradeList']
export type RawTrade = NonNullable<NonNullable<TradeList>['edges'][0]>['node']

export type Trade = RawTrade & {
    skipCounterparties?: boolean
    counterparties: Organization[]
    isEditable: boolean
}

export type Quantity = NonNullable<Trade['quantity']>
export type Status = Trade['tradeStatus']
export type DeliveryTerms = Trade['deliveryTerms']
export type DeliveryTerm = NonNullable<DeliveryTerms[0]>

// if buyer/seller/shipper ever change this might be bad:
export type Organization = NonNullable<RawTrade['buyer']>

// filter types
type MetalsTradeList = TradeListV2FiltersQuery$data['metalsTradeList']
export type Destination = NonNullable<NonNullable<NonNullable<MetalsTradeList>['locations']>[0]>
export type Region = NonNullable<NonNullable<NonNullable<MetalsTradeList>['regions']>[0]>
export type Counterparty = NonNullable<
    NonNullable<
        NonNullable<TradeListV2FiltersQuery$data['myOrganization']>['counterpartyRelationships'][0]
    >['counterparty']
>
export type Commodity = NonNullable<
    NonNullable<NonNullable<TradeListV2FiltersQuery$data['allCommodities']>['edges'][0]>['node']
>

// based off MetalsTradeSortField
export type SortField =
    | 'counterparty'
    | 'delivery_period'
    | 'destination'
    | 'material'
    | 'specification'
    | 'name'
    | 'pk'
    | 'primary_contact'
    | 'quantity'
    | 'region'
    | 'status'
    | 'trade_type'
    | 'trade_fulfillment_type'
    | 'trade_type'

export enum TradeListType {
    Draft = 'Draft',
    Live = 'Live',
    Closed = 'Closed',
}

export interface Settings {
    // from settings dialog

    tradeType?: string

    // set to the last field the user sorted by
    sortField: SortField
    sortDirection: 'asc' | 'desc'

    // table filters
    tradeStatus?: string | TradeStatusNew[]
    tradeFulfillmentType?: string
    pk?: string
    material?: string
    specification?: string
    region?: string
    counterparty?: string
    destination?: string
}

export const multiColumnFilters = ['counterparties', 'destinations', 'materials', 'regions']

export const filtersMappings: Record<string, string> = {
    counterparty: 'counterparties',
    region: 'regions',
    material: 'materials',
    destination: 'destinations',
}
