import {ReactNode, Suspense as RealSuspense} from 'react'
import {isNil} from 'lodash'

import Fallback from '@waybridge/wui/Fallback'

type SuspenseProps = {
    /** the content that might suspend */
    children?: ReactNode

    /** A fallback react tree to show when a Suspense child (like React.lazy) suspends */
    fallback?: ReactNode
}

/**
 * This component is a wrapper for the normal React `Suspense` component with a default
 * fallback already set.
 *
 * @param fallback - optional. will default to the CircularProgress
 * @returns
 */
export const Suspense = ({children, fallback}: SuspenseProps) => {
    const theFallback = isNil(fallback) ? <Fallback /> : fallback
    return <RealSuspense fallback={theFallback}>{children}</RealSuspense>
}

// this is for the older pages
export const FullHeightSuspense = ({children}: {children: ReactNode}) => (
    <RealSuspense fallback={<Fallback height="100vh" />}>{children}</RealSuspense>
)

export default Suspense
