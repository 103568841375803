import {graphql} from 'relay-runtime'

export const getShipmentLinkDelivery = graphql`
    query ShipmentLinkQuery($shipmentPk: Int!) {
        delivery(pk: $shipmentPk) {
            locationConveyance {
                location {
                    pk
                }
            }
            billOfLading {
                buyerOrg {
                    pk
                }
            }
        }
    }
`
