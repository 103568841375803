import {ControllerProps, FieldPath, FieldValues, useController} from 'react-hook-form'

import TextField, {TextFieldProps} from './TextField'

export type Props<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<TextFieldProps, 'value' | 'name' | 'defaultValue'> & ControllerProps<TFieldValues, TName>

/**
 * React Hook Form controlled Input component
 */
export const Controller = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    name,
    control,
    rules,
    error,
    onChange: onChangeUpstream,
    onBlur: onBlurUpstream,
    defaultValue,
    ...props
}: Props<TFieldValues, TName>) => {
    const {
        field: {ref, onChange, onBlur, value},
        fieldState: {invalid},
    } = useController<TFieldValues, TName>({
        name,
        control,
        rules,
        defaultValue,
    })

    const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        onChange(evt?.target?.value ?? '')
        if (onChangeUpstream) {
            onChangeUpstream(evt)
        }
    }

    const handleBlur = (evt: React.FocusEvent<HTMLInputElement>) => {
        onBlur()
        if (onBlurUpstream) {
            onBlurUpstream(evt)
        }
    }

    return (
        <TextField
            {...props}
            error={error ?? invalid}
            onBlur={handleBlur}
            onChange={handleChange}
            ref={ref}
            value={value ?? ''}
        />
    )
}

export default Controller
