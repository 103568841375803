import * as Sentry from '@sentry/react'
import {isNil} from 'lodash'
import posthog from 'posthog-js'
import {URLSearchParamsInit, useSearchParams} from 'react-router-dom'

import Fallback from '@waybridge/wui/Fallback'

import ErrorPage from '@/components/Error/ErrorPage'
import PermissionDeniedPage from '@/components/Error/PermissionDeniedPage'

interface FallbackRendererParams {
    searchParams: URLSearchParams
    setSearchParams: (
        nextInit: URLSearchParamsInit,
        navigateOptions?:
            | {
                  replace?: boolean | undefined
                  state?: object | null | undefined
              }
            | undefined,
    ) => void
}

interface APIError extends Error {
    res?: {status: number}
}

type FallbackProps = {
    error: APIError
}

/**
 * If the ErrorBoundary catches an error called `ChunkLoadError`, that
 * means the user's UI is out-of-date and they are trying to navigate
 * to a new section of the website. We should show a loading indicator
 * and reload the current page.
 *
 * Otherwise if we catch any other error, show our default `ErrorPage`
 */
const fallbackRender =
    ({searchParams, setSearchParams}: FallbackRendererParams) =>
    // eslint-disable-next-line react/display-name, react/function-component-definition -- FIXME
    ({error}: FallbackProps): React.ReactElement => {
        if (error.res?.status === 403) {
            return <PermissionDeniedPage />
        }

        if (error.name !== 'ChunkLoadError') {
            return <ErrorPage />
        }

        const retry = searchParams.get('retry')
        const retryCount = isNil(retry) ? 0 : Number(retry)

        if (Number.isFinite(retryCount) && retryCount >= 5) {
            return <ErrorPage />
        }

        const params = Object.fromEntries(searchParams)
        setSearchParams({
            ...params,
            retry: `${retryCount + 1}`,
        })

        posthog.capture('ChunkLoadError', {
            error: error.message,
        })

        window.location.reload()

        return <Fallback height="100vh" />
    }

/**
 * For Sentry see https://docs.sentry.io/platforms/javascript/guides/react/components/errorboundary/
 */
const ErrorBoundary = ({children}: {children: React.ReactNode}) => {
    const [searchParams, setSearchParams] = useSearchParams()

    return (
        <Sentry.ErrorBoundary fallback={fallbackRender({searchParams, setSearchParams})}>
            {children}
        </Sentry.ErrorBoundary>
    )
}

export default ErrorBoundary
