import {SvgIcon, SvgIconProps} from '@mui/material'
import cn from 'classnames'

export const Warehouse = ({fill, fontSize = 'medium', className}: SvgIconProps) => (
    <SvgIcon
        className={cn('icon icon-factory', className)}
        fill={fill}
        fontSize={fontSize}
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.626.104c.23-.139.518-.139.749 0l7.272 4.364c.28.167.414.502.327.818-.088.314-.375.532-.702.532V16h-1.454V5.818H2.182V16H.728V5.818C.4 5.818.114 5.6.026 5.286c-.087-.316.047-.651.328-.818zM4.364 11.636v1.455h1.454v-1.455h1.455V16H2.909v-4.364h1.455zm5.818 0v1.455h1.454v-1.455h1.455V16H8.727v-4.364h1.455zm-2.91-5.09V8h1.455V6.545h1.455v4.364H5.818V6.545h1.455z"
            fillRule="evenodd"
            transform="translate(-48 -756) translate(48 756)"
        />
    </SvgIcon>
)
