import {SvgIcon} from '@mui/material'
import cn from 'classnames'

import {ConveyanceSVGProps} from './props'

export const Onsite = ({background, fill, fontSize = 'medium', className}: ConveyanceSVGProps) => (
    <SvgIcon
        className={cn('icon icon-onsite', className, {
            'with-background': background,
        })}
        fill={fill}
        fontSize={fontSize}
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 10.032c2.389.553 4 1.584 4 2.768 0 1.768-3.582 3.2-8 3.2s-8-1.432-8-3.2c0-1.184 1.611-2.215 4-2.768l.804 1.252C3.342 11.578 1.6 12.294 1.6 12.8c0 .885 2.865 1.6 6.4 1.6s6.4-.715 6.4-1.6c0-.506-1.821-1.222-3.284-1.516zM8 0c2.651 0 4.8 2.15 4.8 4.8C12.8 8 8 13.6 8 13.6S3.2 8 3.2 4.8C3.2 2.15 5.349 0 8 0zm0 3.2c-.883 0-1.6.717-1.6 1.6 0 .883.717 1.6 1.6 1.6.883 0 1.6-.717 1.6-1.6 0-.883-.717-1.6-1.6-1.6z"
            fillRule="evenodd"
            transform="translate(-822 -7886) translate(822 7886)"
        />
    </SvgIcon>
)
