const MinehubLogo = () => (
    <svg height="60px" version="1.1" viewBox="0 0 193 60" width="193px" xmlns="http://www.w3.org/2000/svg">
        <title>Group 17</title>
        <defs>
            <polygon id="path-1" points="0 58.1917827 192.836719 58.1917827 192.836719 0.100602743 0 0.100602743" />
        </defs>
        <g fill="none" fillRule="evenodd" id="New-Branding-Testing" stroke="none" strokeWidth="1">
            <g id="1.1_Login-Page_design-1" transform="translate(-623.000000, -344.000000)">
                <g id="Group-17" transform="translate(623.000000, 344.000000)">
                    <polygon
                        fill="#121226"
                        id="Fill-1"
                        points="126.767741 33.8070258 126.767741 42.6327795 118.461111 42.6327795 118.461111 33.8070258 110.916234 33.8070258 110.916234 58.0332183 118.461111 58.0332183 118.461111 48.4805605 126.767741 48.4805605 126.767741 58.0332183 134.312289 58.0332183 134.312289 33.8070258"
                    />
                    <polygon
                        fill="#121226"
                        id="Fill-2"
                        points="38.6271793 58.033317 46.1713988 58.033317 46.1713988 33.8071244 38.6271793 33.8071244"
                    />
                    <polygon
                        fill="#121226"
                        id="Fill-3"
                        points="83.9098548 33.8070258 83.9098548 58.0332183 104.600157 58.0332183 104.600157 52.3915743 91.2252523 52.3915743 91.2252523 49.0644509 103.655938 49.0644509 103.655938 43.0430809 91.2252523 43.0430809 91.2252523 39.4480122 104.600157 39.4480122 104.600157 33.8070258"
                    />
                    <g id="Group-16" transform="translate(0.000000, 0.228164)">
                        <path
                            d="M164.025014,47.4630262 C164.025014,55.1463141 158.956739,58.1916841 152.251205,58.1916841 C145.543698,58.1916841 140.628629,55.1463141 140.628629,47.4630262 L140.628629,33.5844504 L148.173506,33.5844504 L148.173506,47.4630262 C148.173506,50.4393551 149.469506,52.2732181 152.365287,52.2732181 C155.261397,52.2732181 156.480137,50.4393551 156.480137,47.4630262 L156.480137,33.5916833 L164.025014,33.5791901 L164.025014,47.4630262 Z"
                            fill="#121226"
                            id="Fill-4"
                        />
                        <path
                            d="M185.59651,50.1222263 C185.59651,48.7035961 184.721003,47.8027742 182.967031,47.8027742 L177.099195,47.8027742 L177.099195,52.5094045 L182.92988,52.5094045 C184.757825,52.5094045 185.59651,51.5066647 185.59651,50.1222263 M184.986976,40.7780068 C184.986976,39.7049109 184.415579,38.8741163 182.662921,38.8741163 L177.099195,38.8741163 L177.099195,42.6815685 L182.662921,42.6815685 C184.377113,42.6815685 184.986976,41.9198151 184.986976,40.7780068 M192.83662,49.9838153 C192.83662,54.9324182 189.521332,57.8055142 182.815798,57.8055142 L170.01229,57.8055142 L170.01229,33.5789929 L182.891085,33.5789929 C189.521332,33.5789929 192.227415,35.8284176 192.227415,39.8778424 C192.227415,42.5083082 190.817004,43.6849658 189.02588,44.619322 C191.426867,45.5536782 192.83662,47.2497878 192.83662,49.9838153"
                            fill="#121226"
                            id="Fill-6"
                        />
                        <polygon
                            fill="#121226"
                            id="Fill-8"
                            points="53.2794428 33.5960559 59.6953335 33.5960559 69.903553 46.3920015 69.903553 33.5960559 76.7093614 33.5960559 76.7093614 57.8051526 70.6830598 57.8051526 60.0849225 44.5246042 60.0849225 57.8051526 53.2794428 57.8051526"
                        />
                        <polygon
                            fill="#121226"
                            id="Fill-10"
                            points="29.3297105 33.7554422 22.5291624 33.7554422 22.5248884 33.7554422 22.3624774 33.9744011 15.5139293 42.9599357 11.2189154 37.3245382 8.56707973 33.7554422 8.49902494 33.7554422 1.69847677 33.7554422 6.57534268e-05 33.7554422 6.57534268e-05 58.0154978 7.54395641 58.0154978 7.54395641 44.9479905 10.7714634 49.1825112 12.3880114 51.3037167 14.6854361 54.3178538 15.5139293 55.3505114 16.3427512 54.3178538 18.6398472 51.3037167 20.256724 49.1825112 23.5388062 44.8763193 23.5480117 58.0154978 31.0919024 58.0154978 31.0919024 33.7554422"
                        />
                        <g id="Clip-13" />
                        <polygon
                            fill="#008C94"
                            id="Fill-12"
                            mask="url(#mask-2)"
                            points="156.480005 26.034083 164.024882 26.034083 164.024882 18.4892061 156.480005 18.4892061"
                        />
                        <polygon
                            fill="#121226"
                            id="Fill-14"
                            mask="url(#mask-2)"
                            points="154.194449 0.100504113 154.194449 12.0423127 146.549627 18.4887787 140.628202 18.4887787 140.628202 26.0339844 148.173408 26.0339844 148.173408 21.0406692 155.117627 15.1849978 169.279271 15.1849978 169.279271 0.100504113"
                        />
                        <path
                            d="M150.159325,11.5015565 L150.159325,4.64939193 C150.159325,4.40413165 149.960421,4.20489877 149.714832,4.20489877 L139.623654,4.20489877 C139.378394,4.20489877 139.179161,4.40413165 139.179161,4.64939193 L139.179161,14.7408991 C139.179161,14.9861594 139.378394,15.1853923 139.623654,15.1853923 L145.791654,15.1853923 L150.159325,11.5015565 Z"
                            fill="#005763"
                            id="Fill-15"
                            mask="url(#mask-2)"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default MinehubLogo
