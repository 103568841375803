import {isEmpty, isNil, reduce} from 'lodash'

export const orgSettingsPath = `org-settings`
const orgSettingsLocationPath = `${orgSettingsPath}/locations`
const orgSettingsUserPath = `${orgSettingsPath}/users`
export const newUserPath = `${orgSettingsUserPath}/new`
export const newLocationPath = `${orgSettingsLocationPath}/new`

export const appointmentsPath = 'appointments'
export const buyerShipmentsPath = 'shipments'
export const editTradePath = 'edit'
export const inventoryPath = 'inventory'
export const loginPath = 'login'
export const profilePath = 'profile'
export const supplierShipmentsPath = 'shipments-supplier'
export const shipmentsPath = 'shipments-better'
export const shipmentsLink = 'shipments-link'
export const newShipmentsPath = `${shipmentsPath}/new`
export const tradeListPath = 'list'
export const tradesPath = 'trades'
export const allTradesPath = 'all'

export const manager = 'manager'

export const passwordPath = 'password'
export const resetPasswordPath = 'resetPassword'
export const newPasswordPath = 'newPassword'
export const registerPath = 'register'

export const bookedAppointmentsPath = 'booked'

export const stockFlowPath = 'stock-n-flow'

export const physicalPositionPath = 'physical-position'

export const invoices = 'invoices'

export enum TradePageView {
    Details = 'details',
    Shipments = 'shipments',
    Schedules = 'schedules',
    Quotas = 'quotas',
}

export const getTradeEditDraftPath = (tradePk: number) => `/${tradesPath}/${editTradePath}/${tradePk}/draft`

export const getTradePath = (tradePk: number) => `/${tradesPath}/${tradePk}/${TradePageView.Details}`

export const getTradeEditPath = (tradePk: number) => `/${tradesPath}/${editTradePath}/${tradePk}`
export const getTradeDetailsPath = (tradePk: number, tradeStartDate: string = '') => {
    if (isEmpty(tradeStartDate)) {
        return `/${tradesPath}/${tradePk}/${TradePageView.Details}`
    }

    return `/${tradesPath}/${tradePk}/${TradePageView.Details}?quotaPeriod=${tradeStartDate}`
}

/**
 * Get the path to a trade's shipment / fulfillment page
 *
 * @param tradePk the PK of the trade
 * @param tradePeriodPk (optional) tradePeriodPk
 */
export const getTradeShipmentsPath = (tradePk: number, tradePeriodPk?: number) =>
    isNil(tradePeriodPk)
        ? `/${tradesPath}/${tradePk}/${TradePageView.Shipments}`
        : `/${tradesPath}/${tradePk}/${TradePageView.Shipments}?tradePeriodPk=${tradePeriodPk}`

/**
 * Get the path to a trade's Quotas & Orders page
 *
 * @param tradePk the PK of the trade
 * @param tradePeriodPk (optional) tradePeriodPk
 */
export const getTradeQuotasPath = (tradePk: number, tradePeriodPk?: number) =>
    isNil(tradePeriodPk)
        ? `/${tradesPath}/${tradePk}/${TradePageView.Quotas}`
        : `/${tradesPath}/${tradePk}/${TradePageView.Quotas}?tradePeriodPk=${tradePeriodPk}`

interface TradeSchedulesPathOptions {
    tradePeriodPk?: number
    schedulePk?: number
    scheduleLineItemPk?: number
}

/**
 * Get the path to a trade's scheduling page
 *
 * @param tradePk the PK of the trade
 * @param opts optional searchParam values
 */
export const getTradeSchedulesPath = (tradePk: number, opts?: TradeSchedulesPathOptions) => {
    const searchParams = reduce(
        opts ?? {},
        (acc, value, key) => {
            if (value) {
                // eslint-disable-next-line no-param-reassign -- FIXME
                acc += `${key}=${value}&`
            }
            return acc
        },
        '',
    )

    if (searchParams !== '') {
        return `/${tradesPath}/${tradePk}/${TradePageView.Schedules}?${searchParams}`
    }

    return `/${tradesPath}/${tradePk}/${TradePageView.Schedules}`
}

export const getTransferCreatePath = (accountId: string, bolLineItemPk: number) =>
    `/inventory/transfers/internal-transfer-create?accountId=${accountId}&lineItemPk=${bolLineItemPk}`
export const inventoryAccountsPath = `${inventoryPath}` as const
export const getInventoryAccountPath = (accountId: string) => `/${inventoryPath}/accounts/${accountId}`

export const newInventoryAccountPath = `${inventoryPath}/accounts/new` as const
export const getInventoryAccountShipmentsPath = (accountId: string) => `${getInventoryAccountPath(accountId)}/shipments`
export const getInventoryAccountReleasesPath = (accountId: string) => `${getInventoryAccountPath(accountId)}/releases`
export const getInventoryActivitiesPath = (accountId: string) => `${getInventoryAccountPath(accountId)}/activities`
export const getInventoryAccountDetailsPath = (accountId: string) => `${getInventoryAccountPath(accountId)}/details`
export const getInventoryAccountTransfersPath = (accountId: string) => `${getInventoryAccountPath(accountId)}/transfers`
export const getInventoryAccountTransferCreatePath = (accountId: string, bolLineItemPk: number) =>
    `${getInventoryAccountTransfersPath(accountId)}/create?lineItemPk=${bolLineItemPk}`
export const getInventoryAccountTransferEditPath = (accountId: string, scheduleLineItemId: string) =>
    `${getInventoryAccountTransfersPath(accountId)}/edit?lineItemId=${scheduleLineItemId}`

export const getConsignmentSchedulesPath = (accountId: string) => `${getInventoryAccountPath(accountId)}/schedules`

export const getInventoryCreateReleasePath = (accountId: string, subquotaPK: number) =>
    getInventoryAccountShipmentsPath(accountId) + `/create-release?subquotaPk=${subquotaPK}`
export const getInventoryUpdateReleasePath = (accountId: string, releasePk: number) =>
    getInventoryActivitiesPath(accountId) + `/edit-release?releasePk=${releasePk}`

export const getInventoryCreateReleaseFilteredByTradeIdPath = (accountId: string, tradeId: string, month?: number) =>
    getInventoryAccountShipmentsPath(accountId) + `/create-release?tradeId=${tradeId}${month ? `&month=${month}` : ''}`

/**
 * Gets the Shipment Details path, handling the legacy paths as well
 */
export const getShipmentDetailsPath = (
    shipmentPk: number,
    /**
     * Whether the user does not have the `newShipmentEdit` flag enabled
     */
    isLegacy?: boolean,
    /**
     * Whether the current organization is the buyer
     */
    isPrincipal?: boolean,
    /**
     * Shipment destination location id
     */
    locationPk?: number,
) => {
    if (isLegacy) {
        if (isNil(locationPk)) {
            return null
        }
        if (isPrincipal) {
            return `/${buyerShipmentsPath}/${locationPk}/edit/${shipmentPk}`
            // eslint-disable-next-line no-else-return -- FIXME
        } else {
            return `/${supplierShipmentsPath}/edit/${shipmentPk}?locationPk=${locationPk}`
        }
    }

    return `/${shipmentsPath}/${shipmentPk}/details`
}

export const getShipmentCreateCopyPath = (shipmentPk: number) => `/${shipmentsPath}/new?copyId=${shipmentPk}`

export const getLocationPath = (pk: number) => `/${orgSettingsLocationPath}/${pk}`
export const getUserPath = (pk: number) => `/${orgSettingsUserPath}/${pk}`

export const getPasswordResetPath = () => `/${passwordPath}/${resetPasswordPath}`

export const getTradeManagerPath = () => `/${tradesPath}/${manager}`

export const getInventoryAccountsPath = () => `/${inventoryPath}`

export const getNewInventoryAccountPath = () => `/${newInventoryAccountPath}`

export const getFullTradeListPath = () => `/${tradesPath}/${tradeListPath}`

export const getNewShipmentPath = (tradePk?: string, tradePeriodPk?: string | null) => {
    if (!tradePk && !tradePeriodPk) {
        return `/${newShipmentsPath}`
    }
    const path = new URLSearchParams()
    if (tradePk) {
        path.set('tradePk', tradePk)
    }

    if (tradePeriodPk) {
        path.set('tradePeriodPk', tradePeriodPk)
    }

    return `/${newShipmentsPath}?${path.toString()}`
}

export const getOrgSettingsPath = () => `/${orgSettingsPath}`

export const getNewUserPath = () => `/${newUserPath}`

export const getNewLocationPath = () => `/${newLocationPath}`
