import {ForwardedRef, forwardRef} from 'react'
import MUIButton, {ButtonProps as MUIButtonProps} from '@mui/material/Button'
import cn from 'classnames'
import {isNil} from 'lodash'

import {error} from '@/utils/log'

import './Button.less'

export type ExtendedButtonProps<C extends React.ElementType> = Omit<
    MUIButtonProps<C, {component: C}>,
    'color' | 'disableElevation' | 'disableRipple' | 'centerRipple' | 'disableTouchRipple' | 'disableFocusRipple'
> & {
    /** Color is only valid for variant=text buttons */
    color?: 'black' | 'blue'
    type?: 'button' | 'submit' | 'reset'
}

export type ButtonProps = Omit<
    MUIButtonProps,
    'color' | 'disableElevation' | 'disableRipple' | 'centerRipple' | 'disableTouchRipple' | 'disableFocusRipple'
> & {
    /** Color is only valid for variant=text buttons */
    color?: 'black' | 'blue'
    type?: 'button' | 'submit' | 'reset'
}

enum ColorTranslation {
    black = 'primary',
    blue = 'secondary',
}
function textButtonPadding(size: ButtonProps['size']) {
    if (size === 'small') {
        return {padding: '4px 11px'}
    }

    return {padding: '7px 17px'}
}

/**
 * Buttons allow users to take actions, and make choices, with a single tap.
 */
// eslint-disable-next-line react/display-name -- FIXME
export const Button = forwardRef(
    <C extends React.ElementType>(allProps: ExtendedButtonProps<C>, ref: ForwardedRef<HTMLElement>) => {
        const {className, variant = 'outlined', color, size, children: _children, ...props} = allProps
        const extraProps: Partial<MUIButtonProps> = {}
        let children: React.ReactNode = _children

        if (isNil(variant) || variant === 'text') {
            extraProps.color = isNil(color) ? 'primary' : ColorTranslation[color]
            extraProps.sx = textButtonPadding(size)
            // we wrap the content so that we can put an underline/border on it
            children = <span className="Button-textContent">{_children}</span>
        }
        if (variant === 'outlined') {
            extraProps.color = 'primary'
            if (!isNil(color)) {
                error('Button only accepts `color` prop when `variant=text`')
            }
        }
        if (variant === 'contained') {
            extraProps.color = 'secondary'
            if (!isNil(color)) {
                error('Button only accepts `color` prop when `variant=text`')
            }
        }

        return (
            <MUIButton
                ref={ref}
                size={size}
                {...props}
                {...extraProps}
                className={cn('Button', className)}
                variant={variant}>
                {children}
            </MUIButton>
        )
    },
)

export default Button
